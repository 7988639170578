.downtime-strip span{
    background-color: yellow;
    font-weight: bold;
}

.downtime-strip p{
   font-size: 20px;
   text-align: center;
}


.links-box{
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    min-height:80vh;
    max-height: fit-content;
    height:fit-content;
    padding:5px;
    /* gap:10px; */
    flex-wrap: wrap;
    /* background-image:url("https://media.istockphoto.com/vectors/abstract-white-and-gray-modern-background-vector-illustration-vector-id1157674417?k=20&m=1157674417&s=612x612&w=0&h=pJdXyNFE5ak5VbNd3sIBqQkUsO0N9yXeOEof5RQcero="); */
    background-repeat: no-repeat;
    background-size: 100%;
}
.link-box{
    display: flex;
    flex-direction:column;
    box-sizing: border-box;
    align-items: center;
    padding:20px;
    width:175px;
    height:175px;
    /* border-radius: 10px; */
    z-index: 1;
    /* background-color: white; */
}
.link-box-title{
    width:100%;
    color: black;
    font-size: medium;
    text-align: center;
}
.link-box-icon{
    display:flex;
    justify-content: center;
    align-items: center;
    padding:5px;
    width:100px;
    height:100px;
    text-align: center;
    line-height: 100px;
    font-size: large;
    vertical-align: middle;
    color: white;
    border:1px solid #FE414D;
    background-color: #FE414D;
    border-radius: 50%;
    margin-bottom: 2px;

}
.links-role-div{
    width: 80%;
    text-align: left;
    margin:auto;
}
/* .link-box:hover{
   cursor: pointer;
   background-color: white;
   border-radius: 10px;
   box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
} */
@media only screen and (max-width: 620px) {
    .links-box{
        flex-direction: column;
        padding:50px;
        align-items: center;
        justify-content:center ;

    }
  }
  /* .link-box:hover{
    transform: scale(1.2);
  } */